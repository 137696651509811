#accordionFaq{
    margin-bottom: 24px;
}

#accordionFaq h5{
    margin-bottom: 0;
}

#accordionFaq .accordion-header{
    background: #852aee0f;
    padding: 16px;
    cursor: pointer;
}

#accordionFaq .open{
    transition: ease-in-out 400ms;
    transform: rotate(180deg);
}

#accordionFaq .closed{
    transition: ease-in-out 400ms;
    transform: rotate(0deg)
}
