.event{
    margin: 24px 0;
}

.event svg {
    margin-right: 8px;
    fill: #8a2def;
}

.event span {
    display: flex;
    align-items: center;
    font-weight: 700;
}

.event p {
    margin: 8px 0 0;
}

#about a{
    color: #8328ee;
    text-decoration: none;
    transition: ease-in-out 300ms;
}

#about a:hover{
    color: #b84ff8;

}