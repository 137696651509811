.primary-btn svg{
    fill: #fff;
    width: 16px;
    margin-right: 8px;
}

.primary-btn{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 200px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    outline: none;
    border: none;
    cursor: pointer;
    height: 46px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.225px;
    text-align: center;
    padding: 0 16px;
    background: linear-gradient(90deg, rgb(130, 39, 238) 0.12%, rgb(184, 79, 248) 99.9%);
    color: white;
    border-radius: 40px;
    max-width: 95vw;
    transition: all 0.35s linear 0s;
    z-index: 1;
}

.mint-btn{
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    outline: none;
    height: 46px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.225px;
    text-transform: uppercase;
    text-align: center;
    padding: 0 16px;
    background: transparent;
    width: max-content;
    max-width: 95vw;
    transition: all 0.35s linear 0s;
    z-index: 1;
    border: 1px solid #8a2def;
    color: #9e3cf3;
    border-radius: 0;
    min-width: 180px;
}

.disabled-mint-btn{
    opacity: 0.5;
    background: #ca77ff17;
    cursor: not-allowed;
}