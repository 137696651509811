@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;800&display=swap);
.primary-btn svg{
    fill: #fff;
    width: 16px;
    margin-right: 8px;
}

.primary-btn{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 200px;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    cursor: pointer;
    height: 46px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.225px;
    text-align: center;
    padding: 0 16px;
    background: linear-gradient(90deg, rgb(130, 39, 238) 0.12%, rgb(184, 79, 248) 99.9%);
    color: white;
    border-radius: 40px;
    max-width: 95vw;
    transition: all 0.35s linear 0s;
    z-index: 1;
}

.mint-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    height: 46px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.225px;
    text-transform: uppercase;
    text-align: center;
    padding: 0 16px;
    background: transparent;
    width: -webkit-max-content;
    width: max-content;
    max-width: 95vw;
    transition: all 0.35s linear 0s;
    z-index: 1;
    border: 1px solid #8a2def;
    color: #9e3cf3;
    border-radius: 0;
    min-width: 180px;
}

.disabled-mint-btn{
    opacity: 0.5;
    background: #ca77ff17;
    cursor: not-allowed;
}
/* commons */

html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b{
  font-weight: 700;
}

h2{
  font-size: 40px;
}

p{
  color: #3d3e43;
  margin: 0;
}

a{
  color: white;
  font-weight: 600;
  transition: ease-in-out 400ms;
  text-decoration: none;
}

a:hover{
  color: #8328ee;
}

.section-padding{
  padding: 80px 0;
}

.header{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  color:#fff;
  padding: 16px 0;
}

.header .mobile-menu{
  position: fixed;
  bottom: 0;
  background: #41066f;
  left: 0;
  right: 0;
  height: 58px;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  align-items: center;
  border-top: 3px solid #9e3cf3;
  box-sizing: border-box;
}

.header .languages{
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.dark-section{
  background: #1d0f41;
  position: relative;
  padding: 80px 0;
  color: #fff;
}

.dark-hero{
  background: #1d0f41;
  position: relative;
  height: 100vh;
  padding: 80px 0;
  color: #fff;
}

.dark-hero h1{
  line-height: 92px;
  font-size: 92px;
  text-align: center;
  font-weight: 500;
}

.dark-hero h1 span{
  font-size: 120px;
  font-weight: 800;
  color: #9e3cf3;
}

.dark-hero .countdown{
  margin-top: 24px;
}

.special-label {
  align-items: center;
  color: #b84ff8;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.225px;
  text-transform: uppercase;
  width: -webkit-max-content;
  width: max-content;
}

.dash{
  width: 50px;
  height: 3px;
  background: linear-gradient(-90deg, rgb(184, 79, 248) 0%, rgb(130, 39, 238) 100%);
}

.basic-infos{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 120px;
  background: linear-gradient(90deg, rgb(130, 39, 238) 0.12%, rgb(184, 79, 248) 99.9%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 40px;
  align-items: center;
}

.basic-infos-mobile{
  position: absolute;
  bottom: -60px;
  left: 0;
  right: 0;
  height: auto;
  background: linear-gradient(90deg, rgb(130, 39, 238) 0.12%, rgb(184, 79, 248) 99.9%);
  display: flex;
  justify-content: space-evenly;
  padding: 16px 24px;
  align-items: center;
}

footer{
  background: linear-gradient(-90deg, rgb(184, 79, 248) 0%, rgb(130, 39, 238) 100%);
  padding: 16px;
}

footer p{
  color: #fff;
  font-weight: 500;
  text-align: center;
}
footer .languages{
  width: 120px;
  margin: 16px auto 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}

.nb{
  color: #fff;
  font-size: 14px;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 678px) {
  .dark-hero h1{
    line-height: 48px;
    font-size: 48px;
  }
  footer{
    margin-bottom: 58px;
  }
}
.benefit-card{
    margin: 24px 0;
}

.benefit-title{
    display: flex;
    align-items: end;
    margin-bottom: 16px;
}

.benefit-card h5{
   font-weight: 700;
    font-size: 16px;
}

.benefit-card p{
   color: #eccdff;
}

.benefit-card svg{
    fill: #8328ee;
    margin-right: 16px;
}

#benefit table{
    color:#8328ee91;
}

#benefit tr{
    border-color: #8328ee91;
    color: #fff;
}

#benefit td svg{
    fill: #00b25f;
}
.event{
    margin: 24px 0;
}

.event svg {
    margin-right: 8px;
    fill: #8a2def;
}

.event span {
    display: flex;
    align-items: center;
    font-weight: 700;
}

.event p {
    margin: 8px 0 0;
}

#about a{
    color: #8328ee;
    text-decoration: none;
    transition: ease-in-out 300ms;
}

#about a:hover{
    color: #b84ff8;

}
#accordionFaq{
    margin-bottom: 24px;
}

#accordionFaq h5{
    margin-bottom: 0;
}

#accordionFaq .accordion-header{
    background: #852aee0f;
    padding: 16px;
    cursor: pointer;
}

#accordionFaq .open{
    transition: ease-in-out 400ms;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

#accordionFaq .closed{
    transition: ease-in-out 400ms;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg)
}

#mint{

}

#mint .minting-info p{
    color: #ffffff;
    margin-bottom: 24px;
    font-size: 18px;
}


#mint .nft-prev video{
    border-radius: 400px;
    box-shadow: -3px 3px 13px 0px rgba(255,165,2,0.98);
}

#mint .quantity{
    width: 300px;
    margin: 24px 0 16px 0;
}

#mint .quantity button{
    padding: 8px 16px;
    background: #b84ff8;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    -webkit-appearance: unset;
    border: none;
    transition: ease-in-out 400ms;
}

#mint .quantity button:hover{
    background: #8328ee;
}

#mint .quantity span{
    font-size: 30px;
    font-weight: 700;
    color: white;
}

#mint .price {
    font-size: 20px;
    font-weight: 700;
    color: white;
    margin-bottom: 24px;
}

#mint .success{
    text-align: center;
}

#mint .success button {
    margin: 24px auto;
}

@-webkit-keyframes rotate {
100%{
          -webkit-transform: rotate(360deg);
                  transform: rotate(360deg)
      }
}

@keyframes rotate {
100%{
          -webkit-transform: rotate(360deg);
                  transform: rotate(360deg)
      }
}

@-webkit-keyframes dash {
0% {
        stroke-dasharray: 1 150;
        stroke-dashoffset: 0;
    }
50% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -35;
     }
100% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -124;
      }
}

@keyframes dash {
0% {
        stroke-dasharray: 1 150;
        stroke-dashoffset: 0;
    }
50% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -35;
     }
100% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -124;
      }
}



.animate-charcter {
    text-transform: uppercase;
    background-image: linear-gradient(
            -225deg,
            #231557 0%,
            #9e3cf3 45%,
            #20FF90 100%
    );
    background-size: 200% auto;
    color: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: textclip 2.5s linear infinite;
            animation: textclip 2.5s linear infinite;
    display: inline-block;
    font-size: 40px;
}
@-webkit-keyframes textclip {
    to {
        background-position: 200% center;
    }
}
@keyframes textclip {
    to {
        background-position: 200% center;
    }
}


/* subtle zoom to attention and then back */
.pop-outin {
    font-size: 90px;
    -webkit-animation: 2s anim-popoutin ease;
            animation: 2s anim-popoutin ease;
    margin-bottom: 24px;
}
@-webkit-keyframes anim-popoutin {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
        opacity: 0;
    }
    25% {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        opacity: 1;
    }
    50% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
    100% {
        /* animate nothing to add pause at the end of animation */
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}
@keyframes anim-popoutin {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
        opacity: 0;
    }
    25% {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        opacity: 1;
    }
    50% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
    100% {
        /* animate nothing to add pause at the end of animation */
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}

.error-message {
    color: #fff;
    margin: 24px 0;
}
