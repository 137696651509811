@import '~bootstrap/dist/css/bootstrap.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;800&display=swap');
/* commons */
@import "assets/buttons.css";

html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b{
  font-weight: 700;
}

h2{
  font-size: 40px;
}

p{
  color: #3d3e43;
  margin: 0;
}

a{
  color: white;
  font-weight: 600;
  transition: ease-in-out 400ms;
  text-decoration: none;
}

a:hover{
  color: #8328ee;
}

.section-padding{
  padding: 80px 0;
}

.header{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  color:#fff;
  padding: 16px 0;
}

.header .mobile-menu{
  position: fixed;
  bottom: 0;
  background: #41066f;
  left: 0;
  right: 0;
  height: 58px;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  align-items: center;
  border-top: 3px solid #9e3cf3;
  box-sizing: border-box;
}

.header .languages{
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.dark-section{
  background: #1d0f41;
  position: relative;
  padding: 80px 0;
  color: #fff;
}

.dark-hero{
  background: #1d0f41;
  position: relative;
  height: 100vh;
  padding: 80px 0;
  color: #fff;
}

.dark-hero h1{
  line-height: 92px;
  font-size: 92px;
  text-align: center;
  font-weight: 500;
}

.dark-hero h1 span{
  font-size: 120px;
  font-weight: 800;
  color: #9e3cf3;
}

.dark-hero .countdown{
  margin-top: 24px;
}

.special-label {
  align-items: center;
  color: #b84ff8;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.225px;
  text-transform: uppercase;
  width: max-content;
}

.dash{
  width: 50px;
  height: 3px;
  background: linear-gradient(-90deg, rgb(184, 79, 248) 0%, rgb(130, 39, 238) 100%);
}

.basic-infos{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 120px;
  background: linear-gradient(90deg, rgb(130, 39, 238) 0.12%, rgb(184, 79, 248) 99.9%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 40px;
  align-items: center;
}

.basic-infos-mobile{
  position: absolute;
  bottom: -60px;
  left: 0;
  right: 0;
  height: auto;
  background: linear-gradient(90deg, rgb(130, 39, 238) 0.12%, rgb(184, 79, 248) 99.9%);
  display: flex;
  justify-content: space-evenly;
  padding: 16px 24px;
  align-items: center;
}

footer{
  background: linear-gradient(-90deg, rgb(184, 79, 248) 0%, rgb(130, 39, 238) 100%);
  padding: 16px;
}

footer p{
  color: #fff;
  font-weight: 500;
  text-align: center;
}
footer .languages{
  width: 120px;
  margin: 16px auto 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}

.nb{
  color: #fff;
  font-size: 14px;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 678px) {
  .dark-hero h1{
    line-height: 48px;
    font-size: 48px;
  }
  footer{
    margin-bottom: 58px;
  }
}