#mint{

}

#mint .minting-info p{
    color: #ffffff;
    margin-bottom: 24px;
    font-size: 18px;
}


#mint .nft-prev video{
    border-radius: 400px;
    box-shadow: -3px 3px 13px 0px rgba(255,165,2,0.98);
}

#mint .quantity{
    width: 300px;
    margin: 24px 0 16px 0;
}

#mint .quantity button{
    padding: 8px 16px;
    background: #b84ff8;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    -webkit-appearance: unset;
    border: none;
    transition: ease-in-out 400ms;
}

#mint .quantity button:hover{
    background: #8328ee;
}

#mint .quantity span{
    font-size: 30px;
    font-weight: 700;
    color: white;
}

#mint .price {
    font-size: 20px;
    font-weight: 700;
    color: white;
    margin-bottom: 24px;
}

#mint .success{
    text-align: center;
}

#mint .success button {
    margin: 24px auto;
}

@keyframes rotate {
100%{
          transform: rotate(360deg)
      }
}

@keyframes dash {
0% {
        stroke-dasharray: 1 150;
        stroke-dashoffset: 0;
    }
50% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -35;
     }
100% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -124;
      }
}



.animate-charcter {
    text-transform: uppercase;
    background-image: linear-gradient(
            -225deg,
            #231557 0%,
            #9e3cf3 45%,
            #20FF90 100%
    );
    background-size: 200% auto;
    color: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2.5s linear infinite;
    display: inline-block;
    font-size: 40px;
}
@keyframes textclip {
    to {
        background-position: 200% center;
    }
}


/* subtle zoom to attention and then back */
.pop-outin {
    font-size: 90px;
    animation: 2s anim-popoutin ease;
    margin-bottom: 24px;
}
@keyframes anim-popoutin {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    25% {
        transform: scale(1.2);
        opacity: 1;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        /* animate nothing to add pause at the end of animation */
        transform: scale(1);
        opacity: 1;
    }
}

.error-message {
    color: #fff;
    margin: 24px 0;
}