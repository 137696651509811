.benefit-card{
    margin: 24px 0;
}

.benefit-title{
    display: flex;
    align-items: end;
    margin-bottom: 16px;
}

.benefit-card h5{
   font-weight: 700;
    font-size: 16px;
}

.benefit-card p{
   color: #eccdff;
}

.benefit-card svg{
    fill: #8328ee;
    margin-right: 16px;
}

#benefit table{
    color:#8328ee91;
}

#benefit tr{
    border-color: #8328ee91;
    color: #fff;
}

#benefit td svg{
    fill: #00b25f;
}